import "./App.css";
import React, { useState, useEffect } from "react";
import useSound from "use-sound";
import passSfx from "./sounds/done.mp3";
import failSfx from "./sounds/warn.mp3";
import UIfx from "uifx";
import QrScanner from "./Qrscanner"

const pass = new UIfx(passSfx);
const fail = new UIfx(failSfx);

function queryURL(param, read) {
    return read
        ? `https://trvwdb8xrh.execute-api.us-east-1.amazonaws.com/beta/classtable?code=${param}&mode=read`
        : `https://trvwdb8xrh.execute-api.us-east-1.amazonaws.com/beta/classtable?code=${param}&mode=countdown`;
}

function messageQueryURL(param) {
    // let queryString = "";
    // param.map((x, i) => {
    //     queryString += `${i > 0 ? "&" : ""}${Object.keys(x)[0]}=${
    //         x[Object.keys(x)[0]]
    //     }`;
    // });
    return `https://trvwdb8xrh.execute-api.us-east-1.amazonaws.com/beta/textmessage`;
}

function App() {
    const [resData, setResData] = useState("");
    //const [isLoading, setIsLoading] = useState(false);
    //const [isUpdated, setIsUpdated] = useState(false);
    const [accessRoot, setAccessRoot] = useState(true);

    const handleScan = async (data) => {
        console.log("scanning..")
        if (data) {
            // call lambda service to pull up userinfo
            let query = data.split("//")[1];
            try {
                // read user info
                let res = await fetch(queryURL(query, true));
                let _data = await res.json();
                console.log("user info:",{ _data });
                // query = qrcode
                _data.query = query;
                setResData(_data);
            } catch (e) {
                console.error({ e });
                alert("This code is not valid");
                setResData({ query: "" });
            }
        }
    };

    return (
        <div className="App-main">
          <div className="App-main-header">
            TaeStudio <strong>QR</strong> checker
          </div>
          <Home
            onScanSuccess={handleScan}
            queryCode={resData.query}
            onChangeResult={setResData}
            data={resData.Item}
            accs={accessRoot}
            onChangeAccess={setAccessRoot}
          />
        </div>
    );
}

function Home(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [playPass] = useSound(passSfx);
    const [playWarn] = useSound(failSfx);
    const [runScanner, setRunScanner] = useState(false);

    const startScanner = () => {
        setRunScanner(true)
    }

    const goback = () => {
        setRunScanner(false);
        props.onChangeResult("");
        props.onChangeAccess(true);
    };

    const use = async () => {
        props.onChangeAccess(false);
        setIsLoading(true);
        console.log(props.data.phonenumber);
        try {
            let res = await fetch(queryURL(props.queryCode, false));
            let _data = await res.json();
            console.log("start use function", {_data})

            if (_data.message == "Fail") {
                //all used
                alert("You have no class pass left");
                setIsLoading(false);
                fail.play();
            }

            if (_data.message == "Expired") {
                //all used
                alert("Expired");
                setIsLoading(false);
                fail.play();
            }
            if (_data.message == "Success") {
                //done
                let res = await fetch(queryURL(props.queryCode, true));
                console.log("read response: ", {res})
                let _data = await res.json();
                _data.query = props.queryCode;
                props.onChangeResult(_data);

                let smsRes = await fetch(messageQueryURL(), {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json",
                    },
                    body: JSON.stringify({
                        studentName: props.data.studentName,
                        className: props.data.className,
                        classCount: _data.Item.classcount,
                        phone: props.data.phonenumber,
                    }),
                });


                setIsLoading(false);
                pass.play();
            }
        } catch (error) {
            console.error(error);
            console.error("Fatching error, calling aws lambda function");
        }
    };

  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);

  const download = (url, name) => {
    let reader = new FileReader();
    reader.onload = function(){
      window.location.href = reader.result;
    }
    if (!url) {
      throw new Error("Resource URL not provided! You need to provide one");
    }
    setFetching(true);
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        setFetching(false);
        reader.readAsDataURL(blob);
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.style = "display: none";

        if (name && name.length) a.download = name;
        document.body.appendChild(a);
        a.click();
      })
      .catch(() => setError(true));
  };

    if (props.queryCode === undefined || props.queryCode == "") {
        return (
            <div className="App-home">
              <p>Scan your code here</p>
              {runScanner ?
               <div style={{display:"flex", flexDirection:"column"}}>
                 <QrScanner
                   onDataChange={props.onScanSuccess}
                   onDisableScanner={setRunScanner}
               /* style={{ width: "50vh" }} */
                 />
                 <div className="c_button" onClick={()=> window.location.reload()}>
                   Refrash Scanner
                 </div>
               </div>
               :
               <div className="c_button" onClick={startScanner}>
                 Start Scanner
               </div>
              }
              {!fetching ?
               <div className="c_button" onClick={()=> download("https://taestudioagreement.s3.amazonaws.com/Agreement_20210113_Tae_Studio_Ryunghee_Cho+2.pdf", "Agreement.pdf")}>
                 Download Agreement
               </div> : "fetching"
              }
            </div>
        );
    } else {
      return (
        <div className="App-result">
          <p>
            Hello, <strong>{props.data.studentName}</strong>!
          </p>{" "}
          <p>
            You have <strong>{props.data.classcount}</strong> classes
            left
          </p>
          <p>{props.data.className}</p>
          {!isLoading ? (
            props.data.classcount != "0" ? (
              <div onClick={use} className="c_button">
                Use 1 class pass
              </div>
            ) : props.accs ? (
              playWarn()
            ) : (
              ""
            )
          ) : (
            <div className="lds-circle">
              <div></div>
            </div>
          )}
          <div className="c_button" onClick={goback}>
            Scan Again
          </div>
        </div>
      );
    }
}
export default App;
