import React, {useRef,useEffect,useState} from "react";
import jsQR from "jsqr";
// import Canvas from "./Canvas"
// import Video from "./Video"

function Qrscanner({onDataChange, onDisableScanner}) {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const drawLine = (begin, end, color, ctx) => {
    ctx.beginPath();
    ctx.moveTo(begin.x, begin.y);
    ctx.lineTo(end.x, end.y);
    ctx.lineWidth = 4;
    ctx.strokeStyle = color;
    ctx.stroke();
  }

  useEffect(()=>{
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    let animationFrameId;
    let imageData;
    let code;

    navigator.mediaDevices
             .getUserMedia({ video: { facingMode: "user", width: 400 } })
             .then(stream => {
               video.srcObject = stream;
               video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
               video.play();
             });

    const width = 400;
    const height = 320;
    canvas.width = width;
    canvas.height = height;

    const render = () => {
      context.drawImage(video,0,0, width, height)
      imageData = context.getImageData(0,0, canvas.width, canvas.height)
      code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: "dontInvert",
      })
      if (code) {
        drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58", context);
        drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58", context);
        drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58", context);
        drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58", context);
        onDataChange(code.data);
        onDisableScanner(false);
      }
      animationFrameId = window.requestAnimationFrame(render)
    }
    render();

    return () => {
      console.log("unmount video comp")
      window.cancelAnimationFrame(animationFrameId)
      // const stream = video.srcObject;
      // const tracks = stream.getTracks();

      // for (let i = 0; i < tracks.length; i++) {
      //   let track = tracks[i];
      //   track.stop();
      // }
      // video.srcObject = null;
    }
  },[])

  return (
    <>
      <video ref={videoRef} style={{display:"none"}}/>
      <canvas ref={canvasRef}/>
    </>
  );
}

export default Qrscanner;
